var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "改价详情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index
    }, [_c('van-cell', {
      attrs: {
        "title": "单号",
        "value": goods.order ? goods.order.num : ''
      }
    }), _c('van-cell', {
      attrs: {
        "title": "制单人",
        "value": goods.order.creator ? goods.order.creator.name : ''
      }
    }), _c('van-cell', {
      attrs: {
        "title": "含税",
        "value": goods.is_tax ? '是' : '否'
      }
    }), _c('div', {
      staticClass: "goods-container"
    }, [_c('div', {
      staticClass: "goods-image"
    }, [_c('van-image', {
      attrs: {
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_vm._v("规格：1 * " + _vm._s(goods.size))]), _c('div', {
      staticClass: "goods-quantity"
    }, [_vm._v("数量： "), goods.large_volume ? _c('span', [_vm._v(_vm._s(goods.large_volume) + _vm._s(goods.large_unit))]) : _vm._e(), goods.least_volume ? _c('span', [_vm._v(_vm._s(goods.least_volume) + _vm._s(goods.least_unit))]) : _vm._e()]), _c('div', {
      staticClass: "goods-price"
    }, [_vm._v("单价：￥" + _vm._s(goods.price))])])])], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }