<template>
  <div>
    <my-nav-bar
      title="改价详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">

        <div v-for="(goods, index) in list" :key="index">
          <van-cell title="单号" :value="goods.order ? goods.order.num : ''" />
          <van-cell title="制单人" :value="goods.order.creator ? goods.order.creator.name : ''" />
          <van-cell title="含税" :value="goods.is_tax ? '是' : '否'" />
          <div class="goods-container">
            <div class="goods-image">
              <van-image :src="goods.image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ goods.name }}</div>
              <div class="goods-size">规格：1 * {{ goods.size }}</div>
              <div class="goods-quantity">数量：
                <span v-if="goods.large_volume">{{ goods.large_volume }}{{ goods.large_unit }}</span>
                <span v-if="goods.least_volume">{{ goods.least_volume }}{{ goods.least_unit }}</span>
              </div>
              <div class="goods-price">单价：￥{{ goods.price }}</div>
            </div>
          </div>
        </div>

      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getPriceChangedDetail } from '@/api/goods-report'
export default {
  components: { myNavBar },
  data() {
    return {
      showEmpty: false,
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0,
        store_id: this.$route.query.store_id,
        goods_id: this.$route.query.goods_id
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getPriceChangedDetail(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/color";
  .list {
    padding: 10px;
    padding-bottom: 0;
  }
  .goods-container {
    display: flex;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px;
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
  }
  .goods-image {
    width: 100px;
    height: 100px;
  }
  .goods-image .van-image {
    width: 100px;
    height: 100px;
  }
  .goods-name {
    font-size: 15px;
  }
  .goods-size {
    color: #989898;
    font-size: 13px;
  }
  .goods-quantity {
    color: #989898;
    font-size: 13px;
  }
  .goods-price {
    color: $amountRedColor;
    font-size: 13px;
  }
</style>
